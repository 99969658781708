function sortByProperty<T>(a: T, b: T, property: keyof T): number {
  if (!a[property] || !b[property]) {
    return 0;
  }
  if (a[property] > b[property]) {
    return 1;
  } else if (a[property] < b[property]) {
    return -1;
  }
  return 0;
}

// eslint-disable-next-line import/prefer-default-export
export { sortByProperty };
