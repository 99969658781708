import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Page, { PageHead } from 'components/core/page';
import { HeadTags } from 'util/constants';
import Gallery from '@browniebroke/gatsby-image-gallery';
import { ImageProp } from 'types/gallery';
import { sortByProperty } from 'util/functions';
import * as styles from 'styles/pages/event.module.scss';

export function Head() {
  return (
    <PageHead>
      <title id={HeadTags.siteTitle}>Catalün Village</title>
    </PageHead>
  );
}

function EventPage({ data }: PageProps<Queries.EventPageQuery>) {
  const images: ImageProp[] = [];
  data.allFile.nodes.forEach(node => {
    const imageData = node.childImageSharp as ImageProp;
    if (imageData) {
      imageData.name = node.name;
      images.push(imageData);
    }
  });
  images.sort((a, b) => sortByProperty(a, b, 'name'));

  return (
    <Page className={styles.Event}>
      <section className={styles.pageCenter}>
        <h1>Catalunathon</h1>
        <section className={styles.gallery}>
          <Gallery
            images={images}
            gutter="1px"
            rowMargin={0}
            lightboxOptions={{
              enableZoom: false,
              imagePadding: 0,
              animationDisabled: true,
              animationDuration: 100,
              wrapperClassName: styles.lightbox,
            }}
          />
        </section>
      </section>
    </Page>
  );
}

export const query = graphql`
  query EventPage {
    allFile(filter: { relativePath: {}, relativeDirectory: { glob: "**/23.04.15-catalunathon/gallery" } }) {
      nodes {
        name
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`;

export default EventPage;
